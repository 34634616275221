.area-desc {
  outline: none !important;
}

.MuiList-root {
  padding: 10px !important;
  border-radius: 25px;
}

.MuiPaper-root.MuiPopover-paper {
  border-radius: 25px;
  overflow-y: hidden;
}

#menu-licensedStates .MuiPaper-root.MuiPopover-paper {
  max-height: 300px;
  overflow: auto;
  top: "200px";
}

::-webkit-scrollbar {
  display: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none !important;
}
