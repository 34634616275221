.react-pdf__Page__canvas {
  width: 100% !important;
  height: 300px !important;
  border: 2px solid #38879f !important;
  border-radius: 25px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none !important;
}
