.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  border-bottom: none !important;
  background: transparent;
  margin-top: 5px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  background: #38879f;
  border-radius: 50%;
  top: 8.5px;
}

.react-datepicker__navigation--previous {
  left: 18px;
}

.react-datepicker__navigation--next {
  right: 18px;
}

.react-datepicker__navigation-icon {
  top: 1px !important;
  right: 0;
}
.react-datepicker__navigation-icon--next {
  left: 0;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #fff;
}

.react-datepicker__current-month {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #0d2645;
  margin-bottom: 10px;
}

.react-datepicker__day-names {
  padding-top: 10px;
  border-top: 1px solid #d7e7ec;
}
.react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: center;
  color: #8692a2 !important;
  text-transform: capitalize !important;
}

.react-datepicker__day.react-datepicker__day--disabled {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #cfd4da !important;
  padding: 4px 0;
}

.react-datepicker__day {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #0d2645 !important;
  padding: 4px 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #38879f;
  color: #fff !important;
}

.react-datepicker {
  border: 2px solid #93bac6;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.9rem;
}
