html,
body,
.App {
  font-family: "Lato", sans-serif !important;
  scroll-behavior: smooth;
  overflow: auto;
  -ms-overflow-style: none !important; /* IE 11 */
  scrollbar-width: none !important; /* Firefox 64 */
}

.App {
  text-align: center;
}
.custom-chip span {
  width: 100%;
  padding: 0 5px;
}
.custom-chip span div {
  width: 100%;
}
.custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress {
  margin: auto;
  width: 820px;
}
.banner-img-auth {
  width: auto;
  height: 720px;
}
.banner-img-auth.nurse-auth-img {
  height: 800px;
}
.ulpoad-label {
  width: 140px !important;
  margin: auto;
}

.flie-upload-img {
  margin: 20px 0 15px;
}

.logo-custom {
  height: 61px;
}

.banner-imag-home,
.who-we-are-img {
  width: 100%;
}

.client-test-img {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  object-fit: cover;
}

.join-hospital-img-1 {
  width: 100%;
  height: auto;
}

.border-right-line:before {
  width: 1px;
  height: 20px;
  content: "";
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.12);
}

.filter-icon::before {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  right: 15px;
  top: -33px;
  bottom: 0;
  margin: auto;
  background: red;
}

.country-list .country {
  text-align: left !important;
}

.red-dot-visible::before {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  right: 5px;
  top: -2px;
  background: red;
}
@-moz-document url-prefix() {
    html,body{scrollbar-width: none;}
}
::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
}
.web-breadcrumb,
.banner-imag-home.who-we-are-banner {
  display: block;
}
.banner-imag-home.banner-img-mobile, .mobile-breadcrumb {
  display: none;
}

.area-desc {
  font-size: 18px !important;
  color: #0d2645;
}
.area-desc::placeholder {
  color: #909fad !important;
  font-size: 18px;
}
.play-puse-button{
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    border:0;
    background: transparent;
    width: 25px;
    height: 25px;
  display: none;
  }
@media screen and (min-width: 320px) and (max-width: 767px) {
  .app-logo-role {
    width: 150px;
    height: 150px;
  }
  .card-adjust-according-device{
    width: 160px !important;
  }
  .card-adjust-according-device img.jobs-profile-candidate-img{
    width: 130px !important;
    height: 130px !important;
  }
  .custom-range.ant-picker-dropdown{
    left: 0 !important;
        right: 0 !important;
        margin: auto;
        width: 93%;
  }
  .h1-heading{
    font-size: 20px;
  }
  .h2-heading{
    font-size: 16px;
  }
  .common-tag-TandC p{
    font-size: 13px;
  }
  .ant-picker-panel-layout .ant-picker-panels .ant-picker-panel .ant-picker-date-panel{
      width: 100% !important;
  }
  .banner-imag-home.who-we-are-banner {
    display: none;
  }
  .banner-imag-home.banner-img-mobile {
    display: block;
  }
  .border-right-line:before {
    display: none;
  }

  .hospital-log-img {
    width: 75px !important;
  }

  .notification-icon {
    height: 20px;
  }

  .logo-icon {
    width: 40px !important;
    height: 40px !important;
    margin: 0 10px 0 5px !important;
  }

  .custom-chip .MuiChip-label {
    width: 100%;
  }

  .back-icon {
    top: 4vh !important;
    width: 30px;
    height: 30px;
  }

  .back-icon-condition {
    width: 25px;
    height: 25px;
  }

  .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress {
    margin: auto;
    width: 100%;
  }

  .custom-otp-input input {
    width: 40px !important;
    height: 40px !important;
    margin: 0 4px !important;
    font-size: 13px !important;
    border-radius: 8px !important;
  }

  .flie-upload-img {
    width: 100px !important;
    height: 100px !important;
  }

  .camera-imgg {
    width: 35px !important;
    height: 35px !important;
    right: -15px !important;
    bottom: 10px !important;
  }

  .ulpoad-label {
    width: 100px !important;
  }

  .mobile-iPad-d-none {
    display: none;
  }

  .w-100-input div {
    width: 100% !important;
  }

  .w-100-mobile,
  .join-nurse-img {
    width: 100%;
  }

  .join-hospital-2 img {
    width: 165px;
  }

  .area-desc,
  .recharts-wrapper {
    width: 100% !important;
  }
  .recharts-wrapper {
    height: auto !important;
  }
  .mb-0-mobile {
    margin-bottom: 0 !important;
  }

  .join-hospital-1 img {
    width: 260px;
  }

  .company-logo {
    height: 24px;
  }

  .mobile-breadcrumb {
    display: block;
  }

  .web-breadcrumb {
    display: none;
  }
  .dashboard-icon-img {
    height: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress {
    margin: auto;
    width: 100%;
  }
  .dashboard-icon-img {
    height: 65px;
  }
  .video-js.vjs-theme-fantasy {
    height: auto !important;
    width: 100%;
  }
  .company-logo {
    height: 34px;
  }

  .join-nurse-img {
    width: 100%;
  }

  .back-icon {
    top: 4vh !important;
    width: 30px;
    height: 30px;
  }

  .custom-chip .MuiChip-label {
    width: 100%;
  }

  .w-100-input div {
    width: 100% !important;
  }

  .area-desc,
  .recharts-wrapper {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .custom-stepper-div .MuiMobileStepper-root .MuiMobileStepper-progress {
    margin: auto;
    width: 100%;
  }
  .area-desc,
  .recharts-wrapper {
    width: 100% !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .responsive-grid-banner,
  .client-syas-responsive {
    padding-right: 0 !important;
  }
  .video-js.vjs-theme-fantasy {
    height: 450px !important;
    width: 360px;
  }
  .app-logo-role {
    height: 150px !important;
  }
  .common-img-div.nurse-div-img {
    right: -40px !important;
  }
  .banner-img-auth {
    height: 530px;
  }
  .banner-img-auth.nurse-auth-img {
    height: 640px;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
  .height-adjust-screen-size{
    height: 50vh !important;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .card-adjust-according-device{
    width: 234px !important;
  }
  .responsive-grid-banner,
  .client-syas-responsive {
    padding-right: 0 !important;
  }
  .video-js.vjs-theme-fantasy {
    height: 520px !important;
    width: 420px;
  }
  .app-logo-role {
    height: 175px !important;
  }
  .common-img-div.nurse-div-img {
    right: -70px !important;
  }
  .banner-img-auth {
    height: 600px;
  }
  .banner-img-auth.nurse-auth-img {
    height: 720px;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
  .common-img-div.hospital-img-div {
    right: -75px !important;
  }
  .height-adjust-screen-size{
    height: 45vh !important;
  }
}
@media screen and (min-width: 1601px) and (max-width: 1900px) {
  .height-adjust-screen-size{
    height: 40vh !important;
  }
  .banner-img-auth.nurse-auth-img {
    height: 676px;
  }
  .banner-img-auth {
    height: 600px;
  }
  .common-img-div.hospital-img-div {
    right: -150px !important;
  }
  .common-img-div.nurse-div-img {
    right: -145px !important;
  }
  .video-js.vjs-theme-fantasy {
    height: 600px !important;
    width: 480px;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
}
@media screen and (min-width: 1901px) and (max-width: 2500px) {
  .common-img-div.nurse-div-img {
    right: -180px !important;
  }
}
